import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link } from 'react-router-dom'

import { Form, Table, Tooltip, OverlayTrigger, Popover} from "react-bootstrap";
import _ from 'lodash'

// internal component
import DuplicateTooltip from "./duplicate-tooltip.component";
import CardTemplateDetailModal from '../card-template/card-template-detail-modal.component'

// external component
import ScrollBar from 'react-scrollbars-custom'
import Select from "react-select";
import TagsInput from 'react-tagsinput'

// icon
import { IconContext } from "react-icons";
import { IoIosCopy } from "react-icons/io";
import { FaTrash, FaInfoCircle } from "react-icons/fa";

// redux action
import { loadZipCodes } from "./../../redux/card-template/card-template.actions"
import { openPopup } from "../../redux/user/user.actions"
import { removeUploadedCardData } from '../../redux/card/card.actions'

// css
import 'react-tagsinput/react-tagsinput.css'

const FirstStep = ({ listNewCard, handleNext, step, duplicateAmount, showVehicleTypePopup, showFuelProfilePopup, showTimeProfilePopup, showPopup, errorMessage }) => {
  const dispatch = useDispatch();

  // redux data
  const { 
    cardTemplates,
    vehicleTypes,
    fuelProfiles,
    timeProfiles,
    statesOption,
    periodGallons,
    periodAmounts,
    listVehicleTypeDescription
  } = useSelector((state) => state.cardTemplate)

  const { uploadedCardData } = useSelector((state) => state.card)

  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    if (step === 0) {
      let tempListNewCard = []
      let tempListCard = []

      if(!duplicateAmount){
        listNewCard = _.uniqBy(listNewCard, 'id');
      }

      if (listNewCard.length > 0 || uploadedCardData !== null) {
        if (listNewCard.length > 0) {
          tempListCard = [...listNewCard]
        } else {
          tempListCard = [...uploadedCardData[0].cards]
        }
        tempListCard.map((card, index) => {
          tempListNewCard.push({...card})

          tempListNewCard[index].vehicle_number = null;

          let tempStates = [...card.states]
          tempListNewCard[index].states = []
          if (tempStates.length > 0) {
            tempStates.map((state) => {
              if (state.hasOwnProperty('value')) {
                tempListNewCard[index].states.push(state)
              } else {
                let indexState = statesOption.findIndex(x => x.id === state)
                if (indexState !== -1) {
                  tempListNewCard[index].states.push(
                    {
                      label: statesOption[indexState].name,
                      value: state
                    }
                  )
                }
              }
            })
          }
        
          let tempZips = [...card.zips]
          tempListNewCard[index].zips = []
          if (tempZips.length > 0) {
            tempZips.map((zip) => {
              if (zip !== '') {
                tempListNewCard[index].zips.push(zip)
              }
            }) 
          }
        })
        setNewCards([...tempListNewCard])
      } else {
        dispatch(removeUploadedCardData())
        addNewCard()
      }
      setCardError({...errorMessage})
      // console.log('listNewCard :>> ', listNewCard);
    }
  }, [step])

  useEffect(() => {
    setCardError({...errorMessage})
  }, [errorMessage])


  // local state / variable
  const [ newCards, setNewCards ] = useState([])
  const [ cardError, setCardError ] = useState({})
  const [ maxGallonFillInput, setMaxGallonFillInput ] = useState(0)

  // for show duplicate input
  const [ showOverlay, setShowOverlay ] = useState(false)
  const [ selectedCardIndex, setSelectedCardIndex ] = useState(-1)

  // for show modal card template
  const [ showModalCardDetailTemplate, setShowModalCardDetailTemplate ] = useState(false)
  const [selectedCardTemplateId, setSelectedCardTemplateId] = useState('')

  // component
  const VehicleTypePopup = () => {
    return (
      <OverlayTrigger
        key="right"
        placement="right"
        trigger="click"
        show={showVehicleTypePopup}
        style={{display: 'inline-block'}}
        overlay={
          <Popover style={{ maxWidth: '400px' }} id="overlay-vehicle-type">
            <Popover.Content className="popup-info p-0">
              <Table className="mb-0">
                <thead className="popup-header-table">
                  <tr className="header-table">
                    <th>Vehicle Type</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody className="popup-body-table">
                  {
                    vehicleTypes.map((vehicleType, index) => {
                      return (
                        <tr className="popup-row-table" key={`${vehicleType.id}-${index}`}>
                          <td>{vehicleType.name}</td>
                          <td>{getVehicleTypeDescription(vehicleType.name)}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Popover.Content>
          </Popover>
        }
      >
        <div onClick={e => e.stopPropagation()}>
          <IconContext.Provider
            value={{ size: '15px', color: "#ffffff" }}
          >
            <span className="d-flex position-relative" style={{ cursor: 'pointer', marginLeft: '5px' }}>
              <FaInfoCircle 
                style={{ zIndex: 2 }} 
                onClick={() => showPopup('vehicle')}
              />
              <div className="position-absolute" style={{ background: '#ef5350', zIndex: 1, width: '13px', height: '13px', top: '1px', left: '1px', borderRadius: '50%' }}></div>
            </span>
          </IconContext.Provider>
        </div>
      </OverlayTrigger>
    )
  }

  const FuelProfilePopup = () => {
    return (
      <OverlayTrigger
        key="right"
        placement="right"
        trigger="click"
        show={showFuelProfilePopup}
        style={{display: 'inline-block'}}
        overlay={
          <Popover style={{ maxWidth: '400px' }} id="overlay-vehicle-type">
            <Popover.Content className="popup-info p-0">
              <Table className="mb-0">
                <thead className="popup-header-table">
                  <tr className="header-table">
                    <th>Fuel Profile</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody className="popup-body-table">
                  {
                    fuelProfiles.map((fuelProfile, index) => {
                      return (
                        <tr className="popup-row-table" key={`${fuelProfile.id}-${index}`}>
                          <td>{fuelProfile.name}</td>
                          <td></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Popover.Content>
          </Popover>
        }
      >
        <div onClick={e => e.stopPropagation()}>
          <IconContext.Provider
            value={{ size: '15px', color: "#ffffff" }}
          >
            <span className="d-flex position-relative" style={{ cursor: 'pointer', marginLeft: '5px' }}>
              <FaInfoCircle 
                style={{ zIndex: 2 }} 
                onClick={() => showPopup('fuelprofile')}
              />
              <div className="position-absolute" style={{ background: '#ef5350', zIndex: 1, width: '13px', height: '13px', top: '1px', left: '1px', borderRadius: '50%' }}></div>
            </span>
          </IconContext.Provider>
        </div>
      </OverlayTrigger>
    )
  }

  const TimeProfilePopup = () => {
    return (
      <OverlayTrigger
        key="right"
        placement="right"
        trigger="click"
        show={showTimeProfilePopup}
        overlay={
          <Popover style={{ maxWidth: '400px' }} id="overlay-vehicle-type">
            <Popover.Content className="popup-info p-0">
              <Table className="mb-0">
                <thead className="popup-header-table">
                  <tr className="header-table">
                    <th>Time Profile Type</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody className="popup-body-table">
                  {
                    timeProfiles.map((timeProfile, index) => {
                      return (
                        <tr className="popup-row-table" key={`${timeProfile.id}-${index}`}>
                          <td>{timeProfile.name}</td>
                          <td></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Popover.Content>
          </Popover>
        }
      >
        <div onClick={e => e.stopPropagation()}>
          <IconContext.Provider
            value={{ size: '15px', color: "#ffffff" }}
          >
            <span className="d-flex position-relative" style={{ cursor: 'pointer', marginLeft: '5px' }}>
              <FaInfoCircle 
                style={{ zIndex: 2 }} 
                onClick={() => showPopup('timeprofile')}
              />
              <div className="position-absolute" style={{ background: '#ef5350', zIndex: 1, width: '13px', height: '13px', top: '1px', left: '1px', borderRadius: '50%' }}></div>
            </span>
          </IconContext.Provider>
        </div>
      </OverlayTrigger>
    )
  }

  const CFNPopup = () => {
    return (
      <IconContext.Provider
        value={{ size: '15px', color: "#ffffff" }}
      >
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip">
              Select from the list below to allow retail access in All states or only selected states.
            </Tooltip>
          }
        >
          <span className="d-flex position-relative" style={{ cursor: 'pointer', marginLeft: '5px' }}>
            <FaInfoCircle style={{ zIndex: 2 }}/>
            <div className="position-absolute" style={{ background: '#ef5350', zIndex: 1, width: '13px', height: '13px', top: '1px', left: '1px', borderRadius: '50%' }}></div>
          </span>
        </OverlayTrigger>
      </IconContext.Provider>
    )
  }

  const PPAPopup = () => {
    return (
      <IconContext.Provider
        value={{ size: '15px', color: "#ffffff" }}
      >
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip">
              Pacific Pride only allows you to lock out retail stations in 5 zip codes.  You can select the first 2 digits of a zipcode to lock out an entire region (98 & 99 is all of Washington) or select a specific Zip code to lock out a specific area or region (98032 – Kent, WA) <br/><br/>
            </Tooltip>
          }
        >
          <span className="d-flex position-relative" style={{ cursor: 'pointer', marginLeft: '5px' }}>
            <FaInfoCircle style={{ zIndex: 2 }} />
            <div className="position-absolute" style={{ background: '#ef5350', zIndex: 1, width: '13px', height: '13px', top: '1px', left: '1px', borderRadius: '50%' }}></div>
          </span>
        </OverlayTrigger>
      </IconContext.Provider>
    )
  }

  const StrictControlPopup = () => {
    return (
      <IconContext.Provider
        value={{ size: '15px', color: "#ffffff" }}
      >
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip">
              Does not allow for multiple swipes at pump.  Driver must finish transaction and wait 15 minutes for transactions to process before another transction can be allowed. <br/><br/>
            </Tooltip>
          }
        >
          <span className="d-flex position-relative" style={{ cursor: 'pointer', marginLeft: '5px' }}>
            <FaInfoCircle style={{ zIndex: 2 }} />
            <div className="position-absolute" style={{ background: '#ef5350', zIndex: 1, width: '13px', height: '13px', top: '1px', left: '1px', borderRadius: '50%' }}></div>
          </span>
        </OverlayTrigger>
      </IconContext.Provider>
    )
  }

  const ControlPopup = (message) => {
    return (
      <IconContext.Provider
        value={{ size: '15px', color: "#ffffff" }}
      >
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="button-tooltip">
              {message}
            </Tooltip>
          }
        >
          <span className="d-flex position-relative" style={{ cursor: 'pointer', marginLeft: '5px' }}>
            <FaInfoCircle style={{ zIndex: 2 }} />
            <div className="position-absolute" style={{ background: '#ef5350', zIndex: 1, width: '13px', height: '13px', top: '1px', left: '1px', borderRadius: '50%' }}></div>
          </span>
        </OverlayTrigger>
      </IconContext.Provider>
    )
  }

  const dropdownStyle = (errorKey = null) => {
    return {
      container: styles => ({ ...styles, minWidth: '150px' }),
      control: styles => ({ ...styles, minWidth: 'max-content', borderColor: cardError.hasOwnProperty(errorKey) ? '#dc3545' : '#ced4da' }),
      valueContainer: styles => ({ ...styles, display: 'inline-flex', flexDirection: 'row', justifyContent: 'space-between', }),
      menu: styles => ({ ...styles, zIndex: 3, position: 'relative'}),
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      singleValue: styles => ({ ...styles, fontSize: '1rem', position: 'relative', top: 'unset', transform: 'unset' }),
      option: styles => ({ ...styles, minHeight: '40px' })
    }
  }

  const CardDropdown = (listData, index, key, placeholder) => {
    let disabledStatus = false
    if (key !== 'card_template_id' && newCards[index].card_template_id === '') {
      disabledStatus = true
    }
    return (
      <div>
        <Select 
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null
          }}
          className={cardError.hasOwnProperty(`data.${index}.${key}`) ? 'is-invalid' : ''}
          styles={dropdownStyle(`data.${index}.${key}`)}
          menuPortalTarget={document.querySelector('body')}
          menuShouldBlockScroll={true}
          options={
            listData.map((data) => {
              let labelName = data.name
              if (key === 'card_template_id') {
                labelName = data.template_name
              } else if (key === 'card_vehicle_type_id') {
                labelName = getVehicleTypeDescription(data.name)
              }
              return {
                label: labelName === '' ? '-' : labelName,
                value: data.id
              }
            })
          }
          value={getDropdownValue(listData, key, index)}
          onChange={(e) => changeCardValue(key, index, e.value)}
          isDisabled={disabledStatus}
        />
        <Form.Control.Feedback type="invalid">
          {cardError.hasOwnProperty(`data.${index}.${key}`) ? cardError[`data.${index}.${key}`] : ''}
        </Form.Control.Feedback>
      </div>
    )
  }

  const CardInput = (key, index, type = 'text', val, formType = 'input') => {
    let maxNumber = 99999
    let placeholder = ''
    if (key === 'pin') {
      placeholder = 'Auto'
    }
    if (key === 'max_gallon_fill') {
      if(val == '' || val <= 300){
        maxNumber = 300
      }else if(val > 300){
        maxNumber = val
      }
    }
    return (
      <div>
        <Form.Control
          type={type}
          as={formType}
          placeholder={placeholder}
          rows={formType === 'textarea' ? 1 : false}
          className="input-card-order"
          min={type === 'number' ? 0 : false}
          max={type === 'number' ? maxNumber : false}
          // value={type === 'number' ? val.toString() : val}
          value={val}
          isInvalid={cardError.hasOwnProperty(`data.${index}.${key}`)}
          onChange={(e) => {
            changeCardValue(key, index, e.target.value, maxNumber);
          }}
          // onBlur={key === 'pin' ? (e) => pinValidation(index, val) : false}
          disabled={newCards[index].card_template_id === ''}
        />
        <Form.Control.Feedback type="invalid">
          {cardError.hasOwnProperty(`data.${index}.${key}`) ? cardError[`data.${index}.${key}`] : ''}
        </Form.Control.Feedback>
      </div>
    )
  }

  const CardToggle = (key, index, val) => {
    let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === newCards[index].card_template_id)
    let cardTemplate = ''
    if (cardTemplateIndex !== -1) {
      cardTemplate = cardTemplates.data[cardTemplateIndex]
    }
    let disabledStatus = false
    let value = val
    if (key === 'is_odometer') {
      disabledStatus = true
      // value = true
    } else {
      if (newCards[index].card_template_id === '') {
        disabledStatus = true
      } else if (cardTemplate.card_network_name === 'PPA' && key === 'is_retail') {
        disabledStatus = true
      }
    }
    return (
      <Form.Check
        id={`${key}-${index}`}
        name={key}
        type="switch"
        className={disabledStatus ? 'toggle-disabled' : ''}
        checked={value}
        onChange={(e) => {
          changeCardValue(key, index, e.target.checked)}
        }
        disabled={disabledStatus}
      />
    )
  }

  const CFNDropdown = (index) => {
    return (
      <Select
        isMulti
        components={{
          IndicatorSeparator: () => null
        }}
        styles={
          {
              container: styles => ({ ...styles, width: 'max-content', minWidth: '150px' }),
              menu: styles => ({ ...styles, zIndex: 3, position: 'relative'}),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              multiValue: styles => ({ ...styles, background: '#ff0100', color: 'white', minWidth: 'unset' }),
              multiValueLabel: styles => ({ ...styles, color: 'white', fontSize: '16px' }),
              multiValueRemove: styles => ({ ...styles, cursor: 'pointer', '&:hover': { background: '#CF1C00', color: 'white' } }),
              valueContainer: styles => ({ ...styles, flexWrap: 'nowrap'}),
              input: styles => ({ ...styles, width: '50px' })
          }
        }
        menuPortalTarget={document.querySelector('body')}
        menuShouldBlockScroll={true}
        options={statesSorted().map((state) => {
          let name = (state && state.name) ? state.name.replace("(", " (") : ''
          return {
            label: name,
            value: state.id
          }
        })}
        value={newCards[index].states}
        onChange={(e) => changeCardValue('states', index, e)}
        disabled={newCards[index].card_template_id === ''}
      />
    )
  }

  // function
  const addNewCard = () => {
    let newCard = {
      id: null,
      card_template_id: '',
      description: '',
      tax_doc: '',
      cost_center: '',
      vehicle_number: '',
      pin: '',
      card_vehicle_type_id: '',
      card_fuel_profile_id: '',
      card_time_profile_id: '',
      card_period_gallon_id: '',
      card_period_amount_id: '',
      max_gallon_fill: '',
      max_gallon_period: '',
      max_amount_fill: '',
      max_amount_period: '',
      is_misc_key: true,
      strict_control: true,
      is_retail: true,
      is_odometer: false,
      misc_1: '',
      misc_2: '',
      misc_3: '',
      misc_4: '',
      delivery_address_name: '',
      delivery_attention_to: '',
      delivery_street_address: '',
      delivery_street_address_secondary: '',
      delivery_city: '',
      delivery_state: '',
      delivery_zipcode: '',
      delivery_special_instruction: '',
      delivery_phone_number: '',
      pickup_name: '',
      pickup_phone_number: '',
      pickup_address: '',
      pickup_address_secondary: '',
      pickup_special_instruction: '',
      is_delivery: true,
      receive_pin_order: true,
      zips:[],
      states:[],
      shipping_method: '',
      requestor: ''
    }
    let tempNewCards = [...newCards]
    tempNewCards.push(newCard)
    setNewCards([...tempNewCards])
  }

  const deleteCard = (index) => {
    let tempNewCards = [...newCards]
    tempNewCards.splice(index, 1)
    setNewCards([...tempNewCards])
    setCardError({})
  }

  const duplicateCard = (amount, card, index) => {
    let tempNewCards = [...newCards]
    let tempCard = {...card}
    if(tempCard.card_type_name !== 'Single'){
      tempCard.pin = ''
    }
    for (let i = 0; i < amount; i++) {
      tempNewCards.push({...tempCard})
    }
    setNewCards([...tempNewCards])
    setShowOverlay(false)
    setSelectedCardIndex(-1)
    setCardError({})
  }

  const changeCardValue = (key, index, value, maxGallonInput) => {
    let tempNewCards = [...newCards]
    tempNewCards[index][key] = value

    // populate data and remove error validation
    if (key === 'card_template_id') {
      tempNewCards[index].pin = ''
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === value)
      let cardTemplate = {}
      if (cardTemplateIndex !== -1) {
        cardTemplate = cardTemplates.data[cardTemplateIndex]
      }

      // populate data from card template
      for (var k in tempNewCards[index]) {
        if (cardTemplate[k] !== undefined) {
          tempNewCards[index][k] = cardTemplate[k]
          if (cardTemplate.max_gallon_fill === '' || cardTemplate.max_gallon_fill === null) {
            tempNewCards[index].max_gallon_fill = 100
          }
          if (cardTemplate.max_gallon_period === '' || cardTemplate.max_gallon_period === null) {
            tempNewCards[index].max_gallon_fill = 200
          }
        }
      }
      tempNewCards[index].states = []
      tempNewCards[index].zips = []
      if (cardTemplate.states.length > 0) {
        cardTemplate.states.map((state) => {
          if (state !== '') {
            tempNewCards[index].states.push(
              {
                label: state,
                value: state.id
              }
            )
          }
        })
      }
      if (cardTemplate.zips.length > 0) {
        cardTemplate.zips.map((zip) => {
          if (zip !== '') {
            tempNewCards[index].zips.push(zip)
          }
        })
      }

      // remove error validation
      if (key === 'card_template_id') {
        let listKeyValidation = ['vehicle_number','card_template_id', 'description', 'tax_doc', 'cost_center', 'pin', 'card_fuel_profile_id', 'card_time_profile_id', 'max_gallon_fill', 'max_gallon_period', 'card_period_gallon_id', 'misc_1', 'misc_2', 'misc_3', 'misc_4']
        let tempCardError = {...cardError}
        listKeyValidation.map((keyValidation) => {
          if (tempCardError.hasOwnProperty(['data.' + index + '.' + keyValidation])) {
            delete tempCardError['data.' + index + '.' + keyValidation]
          }
        })
        setCardError({...tempCardError})
      }
    }

    if(key === 'card_fuel_profile_id'){
      tempNewCards[index]['card_fuel_profile_name'] = fuelProfiles.find(x => x.id === value).name;
    }

    if (key === 'max_gallon_fill' || key === 'max_gallon_period') {
      tempNewCards[index][key] = maxGallonRestriction(tempNewCards[index].card_template_id,value, key)
      setMaxGallonFillInput(maxGallonInput)
    }
    if (key === 'max_amount_fill') {
      tempNewCards[index][key] = maxDollarAmountFillRestriction(value)
    }
    // if(key === 'states'){
    //   let newValue = value.map((item, index) => {
    //     if(item.value === 'All States  (ALL)'){
    //       value.splice(index, 1);
    //       value.unshift(item)
    //     }
    //     return item
    //   })

    //   console.log(newValue)
      
    //   tempNewCards[index, key] = newValue
    // }
    // if (key === 'is_retail') {
    //   if (!value) {
    //     tempNewCards[index].states = []
    //   }
    // }
    setNewCards([...tempNewCards])
  }

  const validateZipcodeValue = (key, index, value) => {
    let status = true
    if (value.length >= 0 && value.length <= 5) {
      value.map((val) => {
        if (val.length > 2) {
          status = false 
        } else {
          let isInt = Number.isInteger(parseInt(val))
          if (!isInt) {
            status = false
          }
        }
      })
    } else {
      status = false
    }
    if (status) {
      changeCardValue(key, index, value)
    }
  }

  const getDropdownValue = (arrayData, key, index) => {
    let propKey = 'name'
    let val = ''
    if (key === 'card_template_id') {
      propKey = 'template_name'
    }
    if (newCards[index][key] !== '') {
      let indexData = arrayData.findIndex(x => x.id === newCards[index][key])
      // console.log('indexData :>> ', indexData);
      if (indexData !== -1) {
        let labelName = ''
        if (arrayData.length > 0 && newCards[index][key] !== '') {
          if (key === 'card_vehicle_type_id') {
            labelName = getVehicleTypeDescription(arrayData[indexData][propKey])
          } else {
            labelName = arrayData[indexData][propKey]
          }
        }
        val = {
          label: labelName === '' ? '-' : labelName,
          value: newCards[index][key]
        }
      } else {
        if (key === 'card_fuel_profile_id') {
          if (newCards[index].hasOwnProperty('card_fuel_profile_name')) {
            val = {
              label: newCards[index].card_fuel_profile_name,
              value: newCards[index].card_fuel_profile_id
            }
          }
        } else if (key === 'card_time_profile_id') {
          if (newCards[index].hasOwnProperty('card_time_profile_name')) {
            val = {
              label: newCards[index].card_time_profile_name,
              value: newCards[index].card_time_profile_id
            }
          }
        } else {
          val = {
            label: '',
            value: newCards[index][key]
          }
        }
      }
    }
    return val
  }

  const getVehicleTypeDescription = (name) => {
    let index = listVehicleTypeDescription.findIndex(x => x.name === name)
    let description = '-'
    if (index !== -1) {
      description = listVehicleTypeDescription[index].description
    }
    return description
  }

  const statesSorted = () => {
    let tempStatesSorted = statesOption.sort(function(a,b){
      if (a.hasOwnProperty('name') && b.hasOwnProperty('name')) {
        return a.name.localeCompare(b.name)
      }
    })

    tempStatesSorted.map((item, index) => {
      if(item.name === 'All States (ALL)'){
        tempStatesSorted.splice(index, 1);
        tempStatesSorted.unshift(item)
      }
    })

    return tempStatesSorted
  }

  const sortDropdownOption = (listOption) => {
    let optionSorted = listOption.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
    })

    return optionSorted
  }

  const timeProfileSorted = (listTimeProfile, cardTemplateId) => {
    let networkName = ''
    let tempListTimeProfile = []

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
      }
    }

    listTimeProfile.map((timeProfile, index) => {
      if ((timeProfile.network === 'C' && networkName === 'CFN') || (timeProfile.network === 'P' && networkName === 'PPA')) {
        tempListTimeProfile.push(timeProfile)
      }
    })
    
    return tempListTimeProfile
  }

  const fuelProfileSorted = (listFuelProfile, cardTemplateId) => {
    let networkName = ''
    let tempListFuelProfile = []

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
      }
    }

    listFuelProfile.map((fuelProfile, index) => {
      if ((fuelProfile.network === 'C' && networkName === 'CFN') || (fuelProfile.network === 'P' && networkName === 'PPA') || fuelProfile.network === 'S') {
        tempListFuelProfile.push(fuelProfile)
      }
    })
    
    return tempListFuelProfile
  }

  const nextStep = () => {
    if (newCards.length > 0) {
      if (cardValidation()) {
        let virtualCard = isVirtualCard(newCards)
        // console.log('newCards :>> ', newCards);
        // console.log('virtualCard :>> ', virtualCard);
        handleNext(newCards, virtualCard)
      }
    } else {
      dispatch(
        openPopup({
          status: 'card_must_be_at_least_one',
          error: true
        })
      )
    }
  }

  const customerPermission = (type) => {
    let status = false
    let roles = user.roles
    let rolePermission = []

    if (type === 'viewPin') {
      rolePermission = ['pin manager 1', 'pin manager 2']
    }

    roles.map((role) => {
      if (rolePermission.includes(role.toLowerCase())) {
          status = true
      }
    })

    return status
  }

  // rule
  const fuelProfileRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }

    if (networkName === 'CFN' || networkName === 'PPA') {
      if (typeName === 'Single' || typeName === 'Vehicle') {
        show = true
      }
    }
    if (networkName === 'WEX' && typeName === 'Vehicle') {
      show = true
    }
    return show
  }

  const timeProfileRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }

    if (networkName === 'CFN' || networkName === 'PPA') {
      if (typeName === 'Single' || typeName === 'Driver') {
        show = true
      }
    }
    return show
  }

  const gallonRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }

    if (networkName === 'CFN' || networkName === 'PPA') {
      if (typeName === 'Single' || typeName === 'Vehicle') {
        show = true
      }
    }
    return show
  }

  const amountRule = (cardTemplateId, field) => {
    let networkName = ''
    let typeName = ''
    let show = false
    let cardTemplate

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }

    if (networkName === 'PPA' && (typeName === 'Single' || typeName === 'Vehicle')) {
      if (field === 'max_amount_fill') {
        if (cardTemplate.max_amount_fill !== '' && cardTemplate.max_amount_fill !== null) {
          show = true
        }
      } else {
        if ((cardTemplate.max_amount_period !== '' && cardTemplate.max_amount_period !== null) || cardTemplate.card_period_amount_name !== '') {
          show = true
        }
      }
    }
    return show
  }

  const miscKeyRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = true

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }
    if ((networkName === 'CFN' && ['Driver', 'Misc'].includes(typeName)) || (networkName === 'PPA' && typeName === 'Misc')) {
      show = false
    }
    return show
  }

  const retailRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }
    
    if (networkName === 'CFN' || networkName === 'PPA') {
      if (typeName === 'Single' || typeName === 'Vehicle') {
        show = true
      }
    }
    return show
  }

  const allowConcurrentRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }
    
    if (networkName === 'CFN' || networkName === 'PPA') {
      if (typeName === 'Single' || typeName === 'Vehicle') {
        show = true
      }
    }
    return show
  }

  const allowRetailStatesRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }
    
    if (networkName === 'CFN') {
      if (typeName === 'Single' || typeName === 'Vehicle') {
        show = true
      }
    }
    return show
  }

  const retailPurchasesZipRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }
    
    if (networkName === 'PPA') {
      if (typeName === 'Single' || typeName === 'Vehicle') {
        show = true
      }
    }
    return show
  }

  const taxRule = (cardTemplateId) =>{
    let network = ''
    let type = ''
    let code = ''

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        network = cardTemplate.card_network_name
        type = cardTemplate.card_type_name
        code = cardTemplate.card_type_driver_name
      }
    }

    let show = false
    if(network === 'CFN'){
      if(type === 'Single'){
        if(code === '1' ||
          code === '4'){
          show = true;
        }
      }else if(type === 'Driver'){
        if(code === '6'){
          show = true;
        }
      }else if(type === 'Vehicle'){
        if(code === 'G'){
          show = true;
        }
      }else if(type === 'Misc'){
        show = true;
      }
    }else if(network === 'PPA'){
      if(type === 'Single'){
        if(code === '1' ||
          code === '4'){
          show = true;
        }
      }else if(type === 'Driver'){
        if(code === '6'){
          show = true;
        }
      }else if(type === 'Vehicle'){
        if(code === 'G'){
          show = true;
        }
      }else if(type === 'Misc'){
        show = true;
      }
    }else if(network === 'Private Site'){
      if(type === 'Driver'){
        if(code === '6'){
          show = true;
        }
      }else if(type === 'Vehicle'){
        if(code === 'G'){
          show = true;
        }
      }
    }else if(network === 'CNG'){
      if(type === 'Single'){
        if(code === '1' ||
          code === '4'){
          show = true;
        }
      }
    }else if(network === 'MFF'){
      if(type === 'Vehicle'){
        if(code === '5'){
          show = true;
        }
      }
    }else if(network === 'WEX'){
      if(type === 'Driver'){
        if(code === '6'){
          show = true;
        }
      }else if(type === 'Vehicle'){
        if(code === 'G'){
          show = true;
        }
      }
    }
    return show;
  }

  const vehicleNumberRule = (cardTemplateId) => {
    let cardTypeDriverName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        cardTypeDriverName = cardTemplate.card_type_driver_name
      }
    }
    if (cardTypeDriverName === '5' || cardTypeDriverName === 'X') {
      show = true
    }
    return show
  }

  const availablePinRule = (cardTemplateId) => {
    let networkName = ''
    let typeName = ''
    let show = false

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        typeName = cardTemplate.card_type_name
      }
    }

    if (networkName === 'Private Site') {
      if (typeName === 'Driver') {
        if (user.user_type_id === 2) {
          show = true && customerPermission('viewPin')
        } else {
          show = true
        }
      }
    } else if (networkName === 'CFN' || networkName === 'PPA') {
      if (['Single', 'Driver'].includes(typeName)) {
        if (user.user_type_id === 2) {
          show = true && customerPermission('viewPin')
        } else {
          show = true
        }
      }
    } else if (networkName !== 'Mobile Fleet Fueling') {
      if (typeName !== 'Vehicle') {
        if (user.user_type_id === 2) {
          show = true && customerPermission('viewPin')
        } else {
          show = true
        }
      }
    }
    return show
  }

  const maxGallonRestriction = (templateId, value, key) => {
    let tempValue = value
    let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === templateId)
    let maxGallonFill = (typeof cardTemplates.data[cardTemplateIndex].max_gallon_fill === 'string' && (cardTemplates.data[cardTemplateIndex].max_gallon_fill !== '' && cardTemplates.data[cardTemplateIndex].max_gallon_fill !== null)) ? parseInt(cardTemplates.data[cardTemplateIndex].max_gallon_fill) : cardTemplates.data[cardTemplateIndex].max_gallon_fill
    let maxGallonPeriod = (typeof cardTemplates.data[cardTemplateIndex].max_gallon_period === 'string' && (cardTemplates.data[cardTemplateIndex].max_gallon_period !== '' && cardTemplates.data[cardTemplateIndex].max_gallon_period !== null)) ? parseInt(cardTemplates.data[cardTemplateIndex].max_gallon_period) : cardTemplates.data[cardTemplateIndex].max_gallon_period
    let limitGallon = 100

    if (key === 'max_gallon_fill') {
      if (maxGallonFill !== '' && maxGallonFill !== null) {
        // if (maxGallonFill > limitGallon) {
          limitGallon = maxGallonFill
        // }
      }
    } else {
      limitGallon = 200
      if (maxGallonPeriod !== '' && maxGallonPeriod !== null) {
        // if (maxGallonPeriod > limitGallon) {
          limitGallon = maxGallonPeriod
        // }
      }
    }

    // console.log('limitGallon :>> ', limitGallon);

    if (parseInt(value) > limitGallon) {
        tempValue = limitGallon
    }

    if (parseInt(value) <= 0) {
        tempValue = 1
    }

    return tempValue
  }

  const maxDollarAmountFillRestriction = (value) => {
    let tempMaxDollarAmountFill = parseInt(value, 10)
    if (Number.isInteger(tempMaxDollarAmountFill)) {
      if (tempMaxDollarAmountFill < 0) {
        tempMaxDollarAmountFill = 0
      } else if (tempMaxDollarAmountFill > 99999) {
        tempMaxDollarAmountFill = 99999
      }
    } else {
      tempMaxDollarAmountFill = 0
    }
    return tempMaxDollarAmountFill
  }

  const pinValidation = (index, value, isOnBlur = true) => {
    let cardTemplateId = newCards[index].card_template_id
    let networkName = ''
    // let typeName = ''
    let tempCardError = {}
    if (isOnBlur) {
      tempCardError = {...cardError}
    }

    if (cardTemplates && cardTemplates.data) {
      let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === cardTemplateId)
      if (cardTemplateIndex !== -1) {
        let cardTemplate = cardTemplates.data[cardTemplateIndex]
        networkName = cardTemplate.card_network_name
        // typeName = cardTemplate.card_type_name
      }
    }

    if (tempCardError.hasOwnProperty(['data.' + index + '.pin'])) {
      delete tempCardError['data.' + index + '.pin']
    }

    if (value === '') {
      if (value.length !== 0) {
        if (networkName === 'PPA') {
          if (value.length !== 4 && value.length !== 5) {
            tempCardError['data.' + index + '.pin'] = 'Pin must be 4 or 5 digits'
          } else {
            let firstChar = value.charAt(0)
            let secondChar = value.charAt(1)
            if (firstChar === '0' && secondChar === '0') {
              tempCardError['data.' + index + '.pin'] = 'First two digit cannot be zero'
            }
          }
        } else if (networkName === 'CFN') {
          if (value.length !== 5) {
            tempCardError['data.' + index + '.pin'] = 'Pin must be 5 digits'
          } else {
            let firstChar = value.charAt(0)
            let secondChar = value.charAt(1)
            if (firstChar === '0') {
              tempCardError['data.' + index + '.pin'] = 'First digit cannot be zero'
            }else if (firstChar === '0' && secondChar === '0') {
              tempCardError['data.' + index + '.pin'] = 'First two digit cannot be zero'
            }
          }
        } else if (networkName === 'WEX') { 
          if (value.length !== 4 && value.length !== 6) {
            tempCardError['data.' + index + '.pin'] = 'Pin must be 4 or 6 digits'
          }
        } else {
          if (value.length !== 4) {
            tempCardError['data.' + index + '.pin'] = 'Pin must be 4 digits'
          }
        }
      }
    }
    if (isOnBlur) {
      setCardError({...tempCardError})
    }
    return tempCardError
  }

  const cardValidation = () => {
    let status = true
    let tempCardError = {}
    newCards.map((card, index) => {
      if (card.card_template_id === '') {
        tempCardError['data.' + index + '.card_template_id'] = 'Card Template field is required'
        status = false
      }
      if (card.card_fuel_profile_id === '') {
        tempCardError['data.' + index + '.card_fuel_profile_id'] = 'Card product type field is required'
        status = false
      }
      if (card.card_time_profile_id === '') {
        tempCardError['data.' + index + '.card_time_profile_id'] = 'Card time profile field is required'
        status = false
      } 
      // if (card.card_vehicle_type_id === '') {
      //   tempCardError['card_vehicle_type_id.' + index] = 'Card vehicle type field is required'
      //   status = false
      // }
      if (card.description !== null && card.description.length > 30) {
        tempCardError['data.' + index + '.description'] = 'The length of description must be 30 characters or fewer'
        status = false
      }
      if (card.tax_doc !== null && card.tax_doc.length > 15) {
        tempCardError['data.' + index + '.tax_doc'] = 'The length of tax exempt doc must be 15 characters or fewer'
        status = false
      }
      if (card.cost_center !== null && card.cost_center.length > 10) {
        tempCardError['data.' + index + '.cost_center'] = 'The length of cost center must be 10 characters or fewer'
        status = false
      }
      if (gallonRule(card.card_template_id)) {
        if (card.max_gallon_fill === '' || card.max_gallon_fill === null) {
          tempCardError['data.' + index + '.max_gallon_fill'] = 'Max gallon field is required'
          status = false
        }
        if (card.max_gallon_period === '' || card.max_gallon_period === null) {
          tempCardError['data.' + index + '.max_gallon_period'] = 'Max gallon period is required'
          status = false
        }
        if (card.card_period_gallon_id === '' || card.card_period_gallon_id === null) {
          tempCardError['data.' + index + '.card_period_gallon_id'] = 'Gallon period is required'
          status = false
        }
        // if(card.max_gallon_fill > maxGallonFillInput){
        //   tempCardError['data.' + index + '.max_gallon_fill'] = `Max gallon field's max is ${maxGallonFillInput}`
        //   status = false
        // }
      }
      if (card.misc_1 !== null && card.misc_1.length > 10) {
        tempCardError['data.' + index + '.misc_1'] = 'The length of misc 1 must be 10 characters or fewer'
        status = false
      }
      if (card.misc_2 !== null && card.misc_2.length > 10) {
        tempCardError['data.' + index + '.misc_2'] = 'The length of misc 2 must be 10 characters or fewer'
        status = false
      }
      if (card.misc_3 !== null && card.misc_3.length > 15) {
        tempCardError['data.' + index + '.misc_3'] = 'The length of misc 3 must be 15 characters or fewer'
        status = false
      }
      if (card.misc_4 !== null && card.misc_4.length > 25) {
        tempCardError['data.' + index + '.misc_4'] = 'The length of misc 4 must be 25 characters or fewer'
        status = false
      }
      if (card.hasOwnProperty('pin')) {
        let indexCardTemplate = cardTemplates.data.findIndex(x => x.id === card.card_template_id)
        if (indexCardTemplate !== -1) {
          let cardTemplate = cardTemplates.data[indexCardTemplate]
          let networkName = cardTemplate.card_network_name
          let typeName = cardTemplate.card_type_name

          let listNetworkRestriction = ['PPA', 'CFN', 'CNG', 'WEX', 'Private Site']
          let listTypeRestriction = ['Single', 'Driver']

          let pinError = pinValidation(index, card.pin, false)
          let copiedError = {}
          copiedError = {...tempCardError, ...pinError}
          tempCardError = {...copiedError}

          if ((listNetworkRestriction.includes(networkName) && listTypeRestriction.includes(typeName))) {
            if (Object.keys(pinError).length > 0) {
              status = false
            }
          } else {
            if (tempCardError.hasOwnProperty(['data.' + index + '.pin'])) {
              delete tempCardError['data.' + index + '.pin']
            }
          }
        }
      }
      if (card.hasOwnProperty('vehicle_number')) {
        if(card.vehicle_number){
          newCards.map((item, idx) => {
            if(card.vehicle_number === item.vehicle_number && index !== idx){
              tempCardError['data.' + index + '.vehicle_number'] = 'Vehicle number must be unique'
              tempCardError['data.' + idx + '.vehicle_number'] = 'Vehicle number must be unique'
              status = false;
            }
          })
        }
      }
      // console.log('tempCardError :>> ', tempCardError);
      setCardError({...tempCardError})
    })
    return status
  }

  const isVirtualCard = (cards) => {
    let isVirtual = true
    let networkName = ''
    let typeName = ''
    cards.map((card) => {
      if (cardTemplates && cardTemplates.data) {
        let cardTemplateIndex = cardTemplates.data.findIndex(x => x.id === card.card_template_id)
        if (cardTemplateIndex !== -1) {
          let cardTemplate = cardTemplates.data[cardTemplateIndex]
          networkName = cardTemplate.card_network_name
          typeName = cardTemplate.card_type_name
          if (networkName !== 'Mobile Fleet Fueling' && networkName !== 'Private Site' && networkName !== 'MFF') {
            if (typeName !== 'Driver' && typeName !== 'Misc') {
              isVirtual = false
            }
          }
        }
      }
    })
    return isVirtual
  }

  // console.log('newCards :>> ', newCards);

  return (
    <div onClick={() => { setShowOverlay(false); setSelectedCardIndex(-1) }}>
      <ScrollBar
        style={{ width: '100%' }}
        noScrollY
        translateContentSizeYToHolder
        thumbXProps={{ className: 'scrollbar' }}
      >
        <div className="table-container">
          <Table className="add-card-table">
            <thead>
              <tr className="header-table">
                <th>&nbsp;</th>
                <th>#</th>
                <th className="required">Template / Network Type</th>
                <th>Description</th>
                <th>Vehicle Number</th>
                <th className="required">PIN</th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Vehicle Type</span>
                    {VehicleTypePopup()}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span class="required">Fuel Profile</span>
                    {FuelProfilePopup()}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span className="required">Time Profile</span>
                    {TimeProfilePopup()}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span className="required">Max. Gallon Per Fill</span>
                    {ControlPopup("Please set your gallon per fill to your vehicles tank size.")}
                  </div>
                </th>
                <th>
                  <span className="required">Max. Gallon Per Period</span>
                </th>
                <th>
                  <span className="required">Gallon Period</span>
                </th>
                <th>Max. Dollar Amount Per Fill</th>
                <th>Max. Dollar Amount Per Period</th>
                <th>Dollar Period</th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Odometer</span>
                    {ControlPopup("This is a required feature")}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Misc Key</span>
                    {ControlPopup("Entry information is the customer's choice.  Transactions sort by Misc Key entry ONLY if Misc Key entry is in the customer vehicle card file.  This is also for Mobile Fueling and will prompt the driver at the pump to enter information.")}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Strict Control</span>
                    {StrictControlPopup()}
                  </div>
                </th>
                <th>Tax Exempt Doc</th>
                <th>Cost Center</th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Misc 1</span>
                    {ControlPopup("10 Characters")}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Misc 2</span>
                    {ControlPopup("10 Characters")}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Misc 3</span>
                    {ControlPopup("15 Characters")}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Misc 4</span>
                    {ControlPopup("25 Characters (VIN)")}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span>Retail Access</span>
                    {ControlPopup("If Retail Access is selected, please view the actual card to verify where access has been granted.  If Pacific Pride - please note that only 5 zip codes can be blocked to reduce retail access.")}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    {/* <span>CFN State Lock In</span> */}
                    <span>CFN Retail Access</span>
                    {CFNPopup()}
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                  {/* <span>PPA Zipcode Lockout</span> */}
                  <span>Pacific Pride Retail Lockout</span>
                  {PPAPopup()}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                newCards.map((card, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ verticalAlign: 'middle' }}>
                        <div className="multi-icon">
                          <FaTrash onClick={() => {deleteCard(index)}}/>
                          <div
                            className="overlay-container"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <OverlayTrigger
                              placement="left"
                              overlay={
                                <Popover>
                                    <Popover.Content>
                                        <span>Duplicate</span>      
                                    </Popover.Content>
                                </Popover>
                              }
                            >
                              <IoIosCopy
                                onClick={() => {
                                  setSelectedCardIndex(index)
                                  setShowOverlay(true);
                                }}
                              />
                            </OverlayTrigger>
                            <DuplicateTooltip
                              show={showOverlay && selectedCardIndex === index}
                              duplicateCard={(amount) => duplicateCard(amount, card, index)}
                            />
                            
                          </div>
                        </div>
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          {CardDropdown(cardTemplates.data, index, 'card_template_id', 'Select Template')}
                          <IconContext.Provider
                            value={{ size: '15px', color: "#ef5350" }}
                          >
                            <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                              <FaInfoCircle
                                onClick={() => {setSelectedCardTemplateId(card.card_template_id); setShowModalCardDetailTemplate(true)}}
                              />
                            </span>
                          </IconContext.Provider>
                        </div>
                      </td>
                      <td>{CardInput('description', index, 'text', card.description)}</td>
                      <td>{vehicleNumberRule(card.card_template_id) ? CardInput('vehicle_number', index, 'text', card.vehicle_number) : '-'}</td>
                      <td>{availablePinRule(card.card_template_id) && CardInput('pin', index, 'number', card.pin)}</td>
                      <td>{CardDropdown(vehicleTypes, index, 'card_vehicle_type_id', '')}</td>
                      <td>{fuelProfileRule(card.card_template_id) && CardDropdown(fuelProfileSorted(fuelProfiles, card.card_template_id), index, 'card_fuel_profile_id', '')}</td>
                      <td>{timeProfileRule(card.card_template_id) && CardDropdown(timeProfileSorted(timeProfiles, card.card_template_id), index, 'card_time_profile_id', '')}</td>
                      <td>{gallonRule(card.card_template_id) ? CardInput('max_gallon_fill', index, 'number', card.max_gallon_fill) : '-'}</td>
                      <td>{gallonRule(card.card_template_id) ? CardInput('max_gallon_period', index, 'number', card.max_gallon_period) : '-'}</td>
                      <td>{gallonRule(card.card_template_id) ? CardDropdown(sortDropdownOption(periodGallons), index, 'card_period_gallon_id', '') : '-'}</td>
                      <td>{amountRule(card.card_template_id, 'max_amount_fill') ? CardInput('max_amount_fill', index, 'number', card.max_amount_fill) : '-'}</td>
                      <td>{amountRule(card.card_template_id, 'max_amount_period') ? CardInput('max_amount_period', index, 'number', card.max_amount_period) : '-'}</td>
                      <td>{amountRule(card.card_template_id, 'card_period_amount_id') ? CardDropdown(sortDropdownOption(periodAmounts), index, 'card_period_amount_id', '') : '-'}</td>
                      <td style={{ verticalAlign: 'middle' }}>{CardToggle('is_odometer', index, card.is_odometer)}</td>
                      <td style={{ verticalAlign: 'middle' }}>{ miscKeyRule(card.card_template_id) ? CardToggle('is_misc_key', index, card.is_misc_key) : '-'}</td>
                      <td style={{ verticalAlign: 'middle' }}>{allowConcurrentRule(card.card_template_id) ? CardToggle('strict_control', index, card.strict_control) : '-'}</td>
                      <td> {taxRule(card.card_template_id) ? CardInput('tax_doc', index, 'text', card.tax_doc): '-'}</td>
                      <td>{CardInput('cost_center', index, 'text', card.cost_center)}</td>
                      <td>{CardInput('misc_1', index, 'text', card.misc_1, 'textarea')}</td>
                      <td>{CardInput('misc_2', index, 'text', card.misc_2, 'textarea')}</td>
                      <td>{CardInput('misc_3', index, 'text', card.misc_3, 'textarea')}</td>
                      <td>{CardInput('misc_4', index, 'text', card.misc_4, 'textarea')}</td>
                      <td style={{ verticalAlign: 'middle' }}> {retailRule(card.card_template_id) ? CardToggle('is_retail', index, card.is_retail): '-'}</td>
                      <td>{allowRetailStatesRule(card.card_template_id) && card.is_retail ? CFNDropdown(index) : '-'}</td>
                      <td>
                        {retailPurchasesZipRule(card.card_template_id) && card.is_retail ?
                          <div>
                            <TagsInput 
                              inputProps={
                                {
                                  placeholder: "Separate multiple Zip Codes with commas",
                                  className: 'tagsinput-zipcode react-tagsinput-input'
                                }
                              }
                              addKeys={[188]}
                              value={card.zips}
                              onChange={(e) => {validateZipcodeValue('zips', index, e)}}
                              addOnBlur={true}
                            />
                          </div>
                          : '-'}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </ScrollBar>
      <button className="big-primary-button mt-3" onClick={() => addNewCard()}>Add New Card</button>
      <div className="button-container">
        <Link className="secondary-button d-flex align-items-center justify-content-center font-weight-normal" to="/card-management">BACK</Link>
        <button className="primary-button" variant="outline-danger" onClick={() => nextStep()}>NEXT</button>
      </div>
      {
        showModalCardDetailTemplate && 
        <CardTemplateDetailModal 
          show={showModalCardDetailTemplate} 
          handleClose={() => setShowModalCardDetailTemplate(false)} 
          cardTemplateId={selectedCardTemplateId} 
        />}
    </div>
  )
}

export default FirstStep;